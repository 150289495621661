<template>
  <div>
    <KTCodePreview v-bind:title="'Tra cứu IMEI cũ'">
      <template v-slot:preview>
        <div>
          <b-row class="mb-5">
            <div class="col-md-12">
              <p class="text-danger">
                <span>&#42;</span> Ghi chú: dữ liệu IMEI cũ được lấy từ Nhanh.vn
                và tổ thức theo namespace theo thứ tự từ mới đến cũ như sau: 3
                (11/2020 - nay), 1 (06/2019-10/2020), 4 (2015-05/2019)
              </p>
            </div>
          </b-row>
          <b-row class="mb-5">
            <div class="col-md-3">
              <b-input
                variant="primary"
                size="sm"
                v-model="searchImeiCode"
                placeholder="Nhập mã IMEI"
                v-on:keyup.enter="onInputSearchingEnter"
              >
              </b-input>
            </div>
            <div class="col-md-3">
              <b-button
                style="fontweight: 600;"
                variant="primary"
                size="sm"
                @click="onFilter"
                >Tìm kiếm</b-button
              >
            </div>
            <div class="col-md-6"></div>
          </b-row>

          <b-table
            :items="imeiHistories"
            :fields="tableColumns"
            bordered
            :busy="onLoading"
            sort-by="createdDateTime"
            :sort-desc="true"
            hover
            show-empty
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
            </template>
            <template #empty="">
              <div class="no-data">Không có dữ liệu</div>
            </template>
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template v-slot:cell(imeiCode)="row">
              <div
                class="d-flex text-primary justify-content-center billCode"
                style="cursor: pointer"
                @click="linkToImei(row.item)"
              >
                <span v-text="row.item.imeiCode"></span>
              </div>
            </template>
            <template v-slot:cell(itemId)="row">
              <div
                class="d-flex text-primary justify-content-center billCode"
                style="cursor: pointer"
                @click="showItemDetailModal(row.item.itemId)"
              >
                <span v-text="row.item.itemId"></span>
              </div>
            </template>
            <template v-slot:cell(productName)="row">
              <p
                v-if="row.item.productCode"
                style="font-size: 12px; margin-bottom: 0"
              >
                {{ row.item.productCode }}
              </p>
              <span
                v-text="row.item.productName"
                style="white-space: normal"
              ></span>
            </template>
            <template v-slot:cell(createdDateTime)="row">
              <span v-text="row.item.createdByName"></span>
              <br />
              <span v-text="row.item.createdDateTime"></span>
            </template>
          </b-table>
        </div>
      </template>
    </KTCodePreview>
    <OldImeiDetail :itemId="currentItemId" />
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { get } from 'lodash';
import { SideBar } from '@/core/config/menu/sideBar';
import { SET_MENU } from '@/core/services/store/modules/menu.module';
import { VclTable } from 'vue-content-loading';
import OldImeiDetail from '../../components/old-imei/OldImeiDetail.vue';

export default {
  name: 'FindImei',
  data() {
    return {
      imeiHistories: [],
      tableColumns: [
        {
          key: 'index',
          label: 'STT',
          thStyle: { textAlign: 'center', width: '5%' },
          tdClass: 'text-center'
        },
        {
          key: 'step',
          label: 'Hành động',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '7%'
          }
        },
        {
          key: 'itemTypeName',
          label: 'Loại',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'itemId',
          label: 'ID hóa đơn',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '5%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'imeiCode',
          label: 'IMEI',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%'
          },
          tdClass: 'text-center'
        },
        {
          key: 'productName',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '22%'
          }
        },
        {
          key: 'createdDateTime',
          label: 'Ngày tạo',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%'
          },
          tdClass: 'width: 15%'
        },
        {
          key: 'companyId',
          label: 'Namspace',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '2%'
          },
          tdClass: 'width: 2%'
        }
      ],
      searchImeiCode: '',
      onLoading: false,
      currentItemId: ''
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    OldImeiDetail
  },
  mounted() {
    this.$store.dispatch(SET_MENU, SideBar.PRODUCT);
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'IMEI', route: '/old-imeis' },
      { title: 'Tra cứu IMEI cũ' }
    ]);
  },
  methods: {
    fetchData() {
      this.onLoading = true;
      ApiService.get(`/old-imei?imeiCode=${this.searchImeiCode.trim()}`).then(
        resp => {
          this.imeiHistories = get(resp, 'data.data', []);
          this.onLoading = false;
        }
      );
    },
    onFilter() {
      this.fetchData();
    },
    onInputSearchingEnter() {
      this.fetchData();
    },
    linkToImei: function(item) {
      const { href } = this.$router.resolve({
        name: 'list-imeis',
        query: { code: item.imeiCode }
      });
      window.open(href, '_blank');
    },
    linkToOrderItem(item) {
      const { href } = this.$router.resolve({
        name: 'edit-order',
        params: { id: item.itemId }
      });
      window.open(href, '_blank');
    },
    showItemDetailModal(itemId) {
      this.currentItemId = itemId;
      this.$bvModal.show('old-imei-item-detail');
    }
  }
};
</script>

<style scoped>
.billCode:hover {
  text-decoration: underline;
}

.no-data {
  text-align: center;
  font-size: 13px;
}
</style>
